/* BackgroundBoxes.scss */
:root {
    --sky-300: #7dd3fc;
    --pink-300: #f472b6;
    --green-300: #6ee7b7;
    --yellow-300: #fde047;
    --red-300: #f87171;
    --purple-300: #c084fc;
    --blue-300: #60a5fa;
    --indigo-300: #818cf8;
    --violet-300: #d8b4fe;
}

body {
    margin: 0;
    overflow: hidden;
    background-color: #0f172a; // Equivalent to slate-900
}

.background-boxes {
    position: fixed;
    left: 90%;
    top: 0%; 
    transform: translate(-50%, -50%) skewX(-48deg) skewY(14deg) scale(0.675); // Apply skew and scale
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw; // Full viewport width
    height: 100vh; // Full viewport height

    .row {
        display: flex;
    
        .col {
            width: 8rem; // Adjust the width as per your design
            height: 6rem; // Adjust the height as per your design
            border: 1px solid #1e293b; // Equivalent to slate-700
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            
            &:hover {
                background-color: var(--dynamic-color);
                transition: background-color 0s;
            }
            
            transition: background-color 2s;
        }
    }
}
