.top-right-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .attention-grabber {
    cursor: pointer;
    background-color: #333;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    position: relative;
  }
  
  .book-call-button {
    position: absolute;
    right: 100%;
    margin-right: 10px;
  }
  
  .book-call-button button {
    background-color: #444;
    color: #FFF;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    white-space: nowrap; /* Ensures text stays in one line */
  }
  
  .book-call-button button:hover {
    background-color: #555;
  }