
// $background-colour: #3D3D3D;
$background-colour: #2b2b2b;

:root {
  --sky-300: #7dd3fc;
  --pink-300: #f9a8d4;
  --green-300: #6ee7b7;
  --yellow-300: #fef08a;
  --red-300: #fca5a5;
  --purple-300: #c4b5fd;
  --blue-300: #93c5fd;
  --indigo-300: #a5b4fc;
  --violet-300: #d8b4fe;
  --hover-color: #ffeb3b;
}

html{
  width: 100%;
  height: 100%;
  background-color: $background-colour;
  // overflow-y: hidden;
  // overflow-x: hidden;
}

.page {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;

  .form-container {
    text-align: center;
    position: absolute;
    text-align: center;
    z-index: 1;

    h2{
      font-size: 1.5em;
      font-weight: 200;
      font-size: 3em;
      margin-bottom: 20px;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
  }

  .input-field {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    
    input {
      padding: 10px;
      font-size: 1em;
      border: none;
      border-radius: 5px;
      width: 300px;
    }
  }

}

.placeholder-vanish-form {
  width: 100%;
  position: relative;
  max-width: 600px;
  margin: 20px auto;
  background: white;
  height: auto;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(25,28,33,0.02), 0px 0px 0px 1px rgba(25,28,33,0.08);
  transition: background 0.2s;
}

.placeholder-vanish-form.input-filled {
  background: #f0f0f0;
}

.placeholder-vanish-canvas {
  position: absolute;
  pointer-events: none;
  transform: scale(0.5);
  top: 20%;
  left: 0.5rem;
  filter: invert(1);
  transition: opacity 0.2s;
}

.placeholder-vanish-canvas.hidden {
  opacity: 0;
}

.placeholder-vanish-canvas.visible {
  opacity: 1;
}

.input-wrapper {
  display: flex;
  align-items: center;
}

.placeholder-vanish-input {
  flex: 1;
  z-index: 50;
  border: none;
  background: transparent;
  color: black;
  height: auto;
  border-radius: 1.5rem;
  padding: 0rem 1rem; 
  padding-right: 3rem; 
  font-size: 1rem;
  font-family: inherit;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  min-height: 3rem;
  max-height: 6rem;
  overflow-y: auto;
  resize: none;
}

.placeholder-vanish-input:focus {
  outline: none;
}

.placeholder-vanish-input.text-transparent {
  color: transparent;
}

.placeholder-vanish-button {
  margin-left: -2.5rem; 
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;
  cursor: pointer;
  position: relative;
  right: 0.5rem; 
  z-index: 100;
}

.placeholder-vanish-button:disabled {
  background: #f0f0f0;
  cursor: default;
}

.back-button:hover {
  background: #333;
}

.placeholder-vanish-placeholder-container {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  align-items: center;
  width: calc(100% - 4rem);
  overflow: hidden;
}

.placeholder-vanish-placeholder {
  color: #757575;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

.caption-text{
  font-size: 1.1rem;
}

.button-container{
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;

  .back-button {
    margin-top: 1rem;
    background: #000;
    color: #fff;
    border: none;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background 0.2s;
  }

  .submit-button {
    margin-top: 1rem;
    background: #fff;
    color: #000;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    cursor: pointer;
  }
}

.submission-message {
  text-align: center;
  padding: 20px;
  border-radius: 1.5rem;
  box-shadow: 0px 2px 3px -1px rgba(0,0,0,0.1), 0px 1px 0px 0px rgba(25,28,33,0.02), 0px 0px 0px 1px rgba(25,28,33,0.08);
}

.submission-message h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.submission-message p {
  font-size: 1.1rem;
  color: #fff;
}

.next-steps{
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: row;
  gap: 10px;
  flex: wrap;

  button{
    margin-top: 1rem;
    background: #fff;
    color: #000;
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    cursor: pointer;

    font-size: 0.9rem;
    font-weight: bold;

    transition: all 0.2s ease-in-out;

    &:hover{
      transform: translateY(-3px);
      box-shadow: 0 8px 0 #000000, 0 12px 24px rgba(0, 0, 0, 0.2);
      // background: darken($color: #fff, $amount: 20%);
    }
  }
}